<template>
    <div class="page_msg container">
      <div class="header_gruop page_width flex_sb">
        <div class="icon_home flex_center" @click="toBack()"><svg t="1729563443244" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6080" id="mx_n_1729563443245" width="16" height="16"><path d="M398.912 513.152l377.28-384.256c27.904-28.416 27.904-74.432 0-102.784-27.84-28.352-73.024-28.352-100.8 0L247.808 461.504C233.792 475.776 226.88 494.464 226.944 513.152 226.88 531.84 233.792 550.464 247.808 564.736l427.584 435.392c27.84 28.352 73.024 28.352 100.8 0 27.904-28.352 27.904-74.368 0-102.656L398.912 513.152z" fill="#16181c" p-id="6081"></path></svg></div>
        <div class="header_title"></div>
        <div></div>
      </div>
  
      <div class="scoller grid_content" :v-infinite-scroll="false" style="overflow:auto">
          <div class="service page_width">
              
              <p style="font-size: 18px;font-weight: bold;margin-bottom:20px;">Refund Policy </p>
              <p style="margin-bottom:10px;">Return policy</p>
              <p style="margin-bottom:10px;">Any replenishment of the game account that has been credited is final.</p>
              <p style="margin-bottom:10px;">If the customer has entered incorrect login credentials/player ID for replenishment, the customer may be notified at the address for correction, but once the order is delivered, no refund will be possible.</p>
              <p style="margin-bottom:10px;">The replenishment of the bonus is provided by the game administration; Refunds are not possible if the bonus replenishment is not credited to your account.</p>
              <p style="margin-bottom:10px;">br.bigplayers.com não é responsável por quaisquer cartões-presente ou outros bens digitais que sejam roubados, perdidos ou utilizados indevidamente sem permissão. Para evitar dúvidas, br.bigplayers.com não tem obrigação de perguntar ou verificar, ao resgatar um Vale-presente, se esse Vale-presente foi recebido corretamente.</p>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      methods:{
          toBack(){
              this.$router.back()
          }
      }
  }
  </script>
  
  <style>
  
  </style> 